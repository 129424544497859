import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Grid, Stack, Typography } from '@mui/material';
import { keyBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import { filterUnChangedFields, getValidatorMessage } from '../../utils/formValidator';
import { createNotification } from '../../utils/notifications';
import { request } from '../../utils/request';
import Loader from '../loader';
import classes from './anketa.module.sass';
import AnketaFields, { INPUT_TYPES } from './AnketaFields';
import { useResponsive } from 'src/hooks';

export function AnketaForm({
  user,
  projectUuid,
  isLocked,
  ableAddMember,
  quota,
  isAccreditedBy,
  hasInvitations,
  onAddNewAccreadition,
  onReloadMembers,
  autoFillTemplate,
  sx,
  formSx,
  controlElements,
  callbackSubmit,
  hiddenFields,
  isSelfRegsiter = false,
  isHidden = false,
}) {
  const isDesktop = useResponsive();
  // const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const countries = useSelector((state) => state.app.countries);

  const userUuid = user?.uuid;
  const roleUuid = user?.projectSettings?.[projectUuid]?.mainRoleId;

  const validatorMessage = getValidatorMessage(lang);

  const fetchAnketaData = async ({ queryKey }) => {
    const [_, projectUuid, roleUuid] = queryKey;
    const [response] = await Promise.all([
      request(
        `/projects/anketas?projectUuid=${projectUuid}&roleUuid=${roleUuid}&isSelfRegsiter=${isSelfRegsiter}&isHidden=${isHidden}`
      ),
    ]);
    return response.message;
  };

  const fetchAnketaUserData = async ({ queryKey }) => {
    const [_, projectUuid, userUuid] = queryKey;
    const [response] = await Promise.all([request(`/users/current/${userUuid}/projects/${projectUuid}/anketas`)]);
    return response.message;
  };

  const { isLoading: isLoadingData, data } = useQuery(['fetchAnketaData', projectUuid, roleUuid], fetchAnketaData, {
    enabled: !!projectUuid && !!roleUuid,
  });

  const {
    isLoading: isLoadingUserData,
    data: userData,
    refetch: refetchUserData,
  } = useQuery(['fetchAnketaUserData', projectUuid, userUuid], fetchAnketaUserData, {
    enabled: !!projectUuid && !!userUuid,
  });

  const fields = data?.fields || [];

  const validationSchema = fields.reduce((schema, field) => {
    if (field.required) {
      let fieldSchema = {};

      switch (field.AnketaFieldSettings?.typeInput) {
        case 'text':
        case 'long_text':
        case 'url':
        case 'telephone':
        case 'popup_list':
        case 'radio_buttons':
          fieldSchema = { [field.uuid]: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD) };
          break;
        case 'email':
          fieldSchema = { [field.uuid]: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD) };
          break;
        case 'number':
          fieldSchema = { [field.uuid]: Yup.number().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD) };
          break;
        case 'yes_no':
          fieldSchema = {
            [field.uuid]: Yup.boolean()
              .isTrue(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
              .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
          };
          break;
        case 'checkboxes':
        case 'popup_list_checkboxes':
        case 'list_input':
          fieldSchema = {
            [field.uuid]: Yup.array()
              .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
              .min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
          };
          break;
        case 'data':
        case 'datetime':
          fieldSchema = { [field.uuid]: Yup.date().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD) };
          break;
        default:
          break;
      }

      return { ...schema, ...fieldSchema };
    }
    return schema;
  }, {});

  const handleAutoFillValue = (field) => {
    let value;
    Object.keys(autoFillTemplate).forEach((valueKey) => {
      const fieldTemplateUuids = autoFillTemplate[valueKey];
      if (fieldTemplateUuids.includes(field.fieldTemplateUuid)) {
        value = user[valueKey];
      }
    });
    return value;
  };

  const getDefaultValues = () => {
    const fieldValueByUuid = keyBy(userData, 'fieldUuid');
    const defaultValues = fields.reduce((result, field) => {
      const typeInput = field.AnketaFieldSettings?.typeInput;
      const value = userData?.length > 0 ? fieldValueByUuid[field.uuid]?.value : handleAutoFillValue(field);

      return {
        ...result,
        [field.uuid]:
          (typeInput === 'data' || typeInput === 'datetime' ? (value ? new Date(value) : '') : value) ||
          (typeInput === 'yes_no' ? false : INPUT_TYPES[typeInput]?.defaultValue || ''),
      };
    }, {});
    return defaultValues;
  };

  const {
    reset,
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: useMemo(() => {
      return getDefaultValues();
    }, [fields, userData]),
    resolver: yupResolver(Yup.object().shape(validationSchema)),
  });

  useEffect(() => {
    if (!isLoadingUserData && !isLoadingData) {
      const defaultValues = getDefaultValues();
      reset(defaultValues);
    }
  }, [isLoadingUserData, isLoadingData, data, userData]);

  const formatValue = (typeInput, value) => {
    let result = value;
    switch (typeInput) {
      case 'number':
        result = parseInt(value);
        break;
      case 'yes_no':
        result = !!value;
        break;
      case 'checkboxes':
      case 'popup_list_checkboxes':
      case 'list_input':
        result = value || [];
        break;
      case 'data':
      case 'datetime':
        result = value ? new Date(value) : null;
        break;
      default:
        result = value || '';
        break;
    }
    return result || '';
  };

  const handleSubmitAnketa = async (values) => {
    try {
      if (userData?.length > 0) {
        // Update
        let newValues = {},
          baseValues = {};
        userData.forEach((fieldUser) => {
          baseValues = { ...baseValues, [fieldUser.uuid]: formatValue(fieldUser.typeInput, fieldUser.value) };
          newValues = { ...newValues, [fieldUser.uuid]: formatValue(fieldUser.typeInput, values[fieldUser.fieldUuid]) };
        }, {});
        const body = filterUnChangedFields(baseValues, newValues);
        await request(`/users/current/${userUuid}/projects/${projectUuid}/anketas`, {
          method: 'PATCH',
          body: Object.keys(body).length > 0 ? body : {},
        });
      } else {
        // Create
        const body = {
          roleUuid: roleUuid,
          anketaUuid: data.uuid,
          fields: data?.fields.map((field) => ({
            fieldUuid: field.uuid,
            fieldTemplateUuid: field.fieldTemplateUuid,
            typeInput: field.AnketaFieldSettings?.typeInput,
            value: formatValue(field.AnketaFieldSettings?.typeInput, values[field.uuid]),
          })),
        };
        await request(`/users/current/${userUuid}/projects/${projectUuid}/anketas`, { method: 'POST', body });
      }
      createNotification(
        MESSAGES[lang][
          projectUuid === '5c7424bb-d5a0-17e7-7f0a-a595cd65b3c7' ? 'SAVE_SUCCESSFULLY2' : 'SAVE_SUCCESSFULLY'
        ]
      );
      refetchUserData();
      onReloadMembers();
      if (callbackSubmit) callbackSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  const splitArrayInHalf = (array) => {
    const half = Math.ceil(array.length / 2);
    return [array.slice(0, half), array.slice(half)];
  };

  const [leftArray, rightArray] = useMemo(() => (fields?.length > 0 ? splitArrayInHalf(fields) : [[], []]), [fields]);

  return (
    <Stack gap="20px" marginY="20px" sx={sx}>
      {roleUuid ? (
        <>
          {isLoadingData || isLoadingUserData ? (
            <Loader />
          ) : (
            <>
              {fields.length === 0 && (
                <Typography variant="body1" align="center">
                  {MESSAGES[lang].SET_UP_WILL_BE_DONE_SOON}
                </Typography>
              )}
              {/* <Typography variant="h5">Anketa</Typography> */}
              <form onSubmit={handleSubmit(handleSubmitAnketa)}>
                <Stack gap="32px" sx={formSx}>
                  <Stack gap={2}>
                    {isDesktop ? (
                      <>
                        {leftArray.map((field, fieldIndex) => (
                          <Grid key={`field-${fieldIndex}`} container columnSpacing={8} rowSpacing={2}>
                            <Grid item xs={12} md={6}>
                              <AnketaFields
                                key={field.uuid}
                                user={user}
                                isLocked={isLocked}
                                field={field}
                                register={register}
                                control={control}
                                errors={errors}
                                countries={countries}
                                autoFillTemplate={autoFillTemplate}
                                projectUuid={projectUuid}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {rightArray[fieldIndex] && (
                                <AnketaFields
                                  key={rightArray[fieldIndex].uuid}
                                  user={user}
                                  isLocked={isLocked}
                                  field={rightArray[fieldIndex]}
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  countries={countries}
                                  autoFillTemplate={autoFillTemplate}
                                  projectUuid={projectUuid}
                                />
                              )}
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    ) : (
                      <>
                        {fields.map((field) => (
                          <AnketaFields
                            key={field.uuid}
                            user={user}
                            isLocked={isLocked}
                            field={field}
                            register={register}
                            control={control}
                            errors={errors}
                            countries={countries}
                            autoFillTemplate={autoFillTemplate}
                            projectUuid={projectUuid}
                          />
                        ))}
                      </>
                    )}
                  </Stack>

                  {/* <Grid container columnSpacing={8} rowSpacing={2}>
                    <Grid item xs={12} md={6}>
                      <Button fullWidth size="large" type="submit" variant="contained" disabled={isSubmitting}>
                        {TEXT[lang].SAVE_CHANGES}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button fullWidth size="large" variant="outlined" onClick={() => reset()}>
                        {TEXT[lang].CANCEL}
                      </Button>
                    </Grid>
                  </Grid> */}
                  <Stack>
                    {controlElements ? null : (
                      <Grid container spacing={2} justifyContent="center">
                        {fields.length > 0 && (
                          <Grid item xs={12} sm={6} className={classes.button}>
                            <Button
                              type="submit"
                              className={classes.submitButton}
                              variant="contained"
                              size="large"
                              disabled={isSubmitting || isLocked}
                            >
                              {TEXT[lang].SAVE_CHANGES}
                            </Button>
                          </Grid>
                        )}
                        {/* {hasInvitations && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.button}
                            style={{ justifyContent: 'flex-start' }}
                          >
                            <Button
                              className={classes.secondaryButton}
                              variant="contained"
                              size="large"
                              endIcon={<AddIcon />}
                              onClick={onAddNewAccreadition}
                            >
                              {TEXT[lang].ADD_MEMBER}
                            </Button>
                          </Grid>
                        )} */}
                      </Grid>
                    )}
                    {/* {(!ableAddMember || isAccreditedBy) && (
                      <div style={{ color: 'red', textAlign: 'center', marginTop: '16px' }}>
                        {isAccreditedBy
                          ? 'Вы были добавлены. Невозможность еще добавить участников'
                          : `* Квота ${quota}. Невозможность добавить еше`}
                      </div>
                    )} */}
                  </Stack>
                </Stack>
              </form>
            </>
          )}
        </>
      ) : (
        <Alert severity="info" sx={{ alignItems: 'center' }}>
          {MESSAGES[lang].WAIT_UNTIL_ADMIN_SET_ROLE}
        </Alert>
      )}
      {controlElements
        ? controlElements(() => {
            handleSubmit(handleSubmitAnketa)();
          }, isSubmitting)
        : null}
    </Stack>
  );
}

export default AnketaForm;
