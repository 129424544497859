import {
  AutoStories,
  Badge,
  BookOnline,
  ContentPaste,
  FmdGood,
  HomeRepairService,
  People,
  PeopleAltTwoTone,
  JoinFullRounded,
  PersonOutline,
  CalendarMonth,
  PhoneIphoneSharp,
  InstallMobile,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from 'src/hooks';
import { routes } from 'src/utils/routes';
import './style.sass';
import { JoinedProject } from 'src/components/joined-project';

function Sidebar({ callback }) {
  const navigate = useNavigate();
  const { projectUuid } = useParams();
  const location = useLocation();
  const { localizeText } = useLanguage();

  const projectSettings = useSelector((state) => state.app.projectSettings);
  const roles = useSelector((state) => state.app.roles);
  const projects = useSelector((state) => state.app.projects);
  const currentUser = useSelector((state) => state.auth.user);
  const mainRoleId = currentUser?.projectSettings?.[projectUuid]?.mainRoleId;
  const joinedProjects = currentUser?.projectUuids?.includes(projectUuid);

  var typeProcessBDK = projectSettings?.typeProcessBDK;

  const iconSize = { height: '24px', width: '24px' };
  const PROJECT_DETAIL_TABS = useMemo(() => {
    return {
      ABOUT: {
        key: 'about',
        title: 'О мероприятии',
        icon: <FmdGood sx={{ ...iconSize }} />,
        path: routes.projectDetail.path.replace(':projectUuid', projectUuid),
      },
      CALENDARS: {
        key: 'calendars',
        icon: <CalendarMonth sx={{ ...iconSize }} />,
        title: 'Мой календарь',
        path: routes.calendar.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects,
      },
      ACCREDITATION: {
        key: 'accreadition',
        title: 'Моя анкета',
        icon: <PersonOutline sx={{ ...iconSize }} />,
        path: routes.projectAccreadition.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects,
      },
      PARTICIPANTS: {
        key: 'participants',
        title: 'Мой список участников',
        icon: <People sx={{ ...iconSize }} />,
        path: routes.projectAccreadition.path.replace(':projectUuid', projectUuid),
        search: 'tab=members',
        isHidden: !joinedProjects,
      },
      BUSINESS_PROGRAMS: {
        key: 'business-programs',
        title: 'Деловая программа',
        icon: <ContentPaste sx={{ ...iconSize }} />,
        path: routes.projectBusinessProgram.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects,
      },
      EXPONENTS: {
        key: 'Exponents',
        title: 'Экспоненты',
        icon: <PeopleAltTwoTone sx={{ ...iconSize }} />,
        path: 'https://promexpo.expoforum.ru/ru/participants/',
        isHidden: projectUuid != '5c7424bb-d5a0-17e7-7f0a-a595cd65b3c7',
      },
      BUY_TICKET: {
        key: 'buy-ticket',
        title: 'Пакеты услуг участника',
        icon: <HomeRepairService sx={{ ...iconSize }} />,
        path: routes.projectBuyTicket.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects,
      },
      FILES: {
        key: 'files',
        title: 'Информационные материалы',
        icon: <AutoStories sx={{ ...iconSize }} />,
        path: routes.projectInfoFiles.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects,
      },
      ORDERS: {
        key: 'orders',
        title: localizeText.ORDERS,
        icon: <Badge sx={{ ...iconSize }} />,
        path: routes.myOrder.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects,
      },
    };
  }, [projectUuid, localizeText, joinedProjects]);

  if (typeProcessBDK !== '') {
    PROJECT_DETAIL_TABS['BDK'] = {
      key: 'bdk',
      // title: typeProcessBDK === 'bdk' ? localizeText.BDK : localizeText.NETWORKING,
      title: localizeText.BDK,
      icon: <Badge sx={{ ...iconSize }} />,
      path: routes.projectVizits.path.replace(':projectUuid', projectUuid),
      isHidden: !joinedProjects || !projects.find((p) => p.uuid === projectUuid)?.modules.BDK,
    };
  }

  if (
    projectUuid === '5c7424bb-d5a0-17e7-7f0a-a595cd65b3c7' &&
    roles?.some(
      (el) =>
        el.id === mainRoleId &&
        (el.name.ru_RU.toLowerCase() === 'экспонент' ||
          el.name.ru_RU.toLowerCase() === 'делегат' ||
          el.name.ru_RU.toLowerCase() === 'вип' ||
          el.name.ru_RU.toLowerCase() === 'vip')
    )
  ) {
    //TODO hardcode
    PROJECT_DETAIL_TABS.BOOKING = {
      key: 'booking',
      title: localizeText.BOOKING_TITLE,
      icon: <BookOnline sx={{ ...iconSize }} />,
      path: routes.projectBooking.path.replace(':projectUuid', projectUuid),
      search: 'tab',
      isHidden: !joinedProjects,
    };
  }
  if (projectUuid === '5c7424bb-d5a0-17e7-7f0a-a595cd65b3c7') {
    //TODO hardcode
    PROJECT_DETAIL_TABS.MOBILE_APP = {
      key: 'booking',
      title: localizeText.MOBILE_APP,
      icon: <InstallMobile sx={{ ...iconSize }} />,
      path: 'https://apps.efi.cubesk.com/',
      isHidden: !joinedProjects,
    };
  }
  const handleNavigate = (path) => () => {
    if (path?.startsWith('http')) {
      window.open(path, '_blank');
      return;
    }
    navigate(path);
    callback?.();
  };

  return projectUuid ? (
    <Stack className="sidebar" gap="8px">
      {Object.values(PROJECT_DETAIL_TABS).map((tab) => {
        const isActive = tab.search
          ? location?.pathname === tab.path && location.search?.includes(tab.search)
          : location?.pathname === tab.path && !location.search;

        return (
          !tab.isHidden && (
            <div
              className={classNames('sidebar__tab', { selected: isActive })}
              key={tab.key}
              onClick={handleNavigate(tab.path + (tab.search ? `?${tab.search}` : ''))}
            >
              <span className="sidebar__tab-icon">{tab.icon}</span>
              <span className="sidebar__tab-title">{tab.title}</span>
            </div>
          )
        );
      })}
    </Stack>
  ) : (
    <></>
  );
}

export default Sidebar;
