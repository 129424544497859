import { Check } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import UserIcon from 'src/layouts/components/UserIcon';
import { TEXT } from 'src/utils/constant';
import { createNotification } from 'src/utils/notifications';

function StepFooter({
  activeStep,
  steps,
  checkAbleNext,
  isSubmitting,
  lockChangeProject,
  handlePrev = () => {},
  handleNext = () => {},
}) {
  const stepCondition = activeStep === steps.length - 1;
  const ableNext = checkAbleNext ? checkAbleNext(activeStep) : true;
  const lang = useSelector((state) => state.app.lang);

  return (
    <Box
      sx={{
        mt: 4,
        display: 'flex',
        justifyContent: !stepCondition ? 'space-between' : 'center',
        fontSize: '1rem !important',
      }}
    >
      {!stepCondition && (
        <Button
          size="large"
          variant="outlined"
          onClick={handlePrev}
          disabled={!handlePrev || activeStep === 0 || (activeStep === 1 && lockChangeProject)}
          startIcon={<UserIcon icon="mdi:arrow-left" />}
        >
          {TEXT[lang].PREVIOUS}
        </Button>
      )}

      <Button
        variant="contained"
        size="large"
        color={stepCondition ? 'success' : 'primary'}
        {...(isSubmitting
          ? { endIcon: <Loader color="rgba(0, 0, 0, 0.26)" size="12px" /> }
          : !stepCondition
          ? { endIcon: <UserIcon icon="mdi:arrow-right" /> }
          : {})}
        type="submit"
        disabled={!ableNext || isSubmitting}
        onClick={handleNext}
      >
        {stepCondition ? TEXT[lang].SUBMIT : TEXT[lang].NEXT}
      </Button>
    </Box>
  );
}

export default StepFooter;
