import { Box, Button, Card, CardContent, Container, Stack, StepLabel, Stepper, Typography } from '@mui/material';
import MuiStep from '@mui/material/Step';
import { styled } from '@mui/material/styles';
import { LanguageMuiSelect } from 'src/components/language-select';
import qs from 'query-string';

// ** React Imports
import { useEffect, useMemo, useState } from 'react';

// ** Styled Components
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StepperWrapper from 'src/@core/styles/mui/stepper';
import { ConfirmModal, ProfileSummary } from 'src/components';
import Loader from 'src/components/loader';
import useInactivityUser from 'src/hooks/useInactivityUser';
import { projectsAPIs } from 'src/services';
import { logout } from 'src/store/actions/auth';
import AlertPopup from './AlertPopup';
import ErrorPopup from './ErrorPopup';
import StepFour from './StepFour';
import StepOne from './StepOne';
import StepperCustomDot from './StepperCustomDot';
import StepThree from './StepThree';
import StepTwo from './StepTwo';
import { request } from 'src/utils/request';
import { useLanguage } from 'src/hooks';
import StepOptions from './StepOptions';
import moment from 'moment-timezone';
import ImageCropperModal from 'src/components/modal/ImageCropperModal';
import { routes } from 'src/utils/routes';

const Step = styled(MuiStep)(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(4),
  },
  '& .MuiStepLabel-root': {
    padding: 0,
    cursor: 'pointer',
  },
}));

const StepperHeaderContainer = styled(CardContent)(({ theme }) => ({
  minWidth: 300,
  borderRight: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    borderRight: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

function SelfRegistrationPage() {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const { localizeText, getLocalizationValue } = useLanguage();

  const steps = [
    { title: localizeText.REGISTRATION_FOR_THE_EVENT },
    { title: localizeText.OPTIONS },
    { title: localizeText.YOUR_PROFILE },
    { title: localizeText.ANKETA },
    { title: localizeText.TICKET },
  ];

  // ** States
  const [activeStep, setActiveStep] = useState(0);
  const [selectedProject, setSelectedProject] = useState('');
  const [hasError, setHasError] = useState(false);
  const [lockChangeProject, setLockChangeProject] = useState(false);

  // account options
  const [accountOption, setAccountOption] = useState(null);
  const [ticketNumber, setTicketNumber] = useState('');

  const fetchAnketaUserData = async ({ queryKey }) => {
    const [, projectUuid, userUuid] = queryKey;
    const [response] = await Promise.all([request(`/users/current/${userUuid}/projects/${projectUuid}/anketas`)]);
    return response.message;
  };

  const userUuid = user?.uuid;
  const projectUuid = selectedProject;

  const { data: userData, refetch: refetchAnketaUserData } = useQuery(
    ['fetchAnketaUserData', projectUuid, userUuid],
    fetchAnketaUserData,
    {
      enabled: !!projectUuid && !!userUuid,
    }
  );

  useEffect(() => {
    if (activeStep > 1) {
      setLockChangeProject(true);
    } else if (activeStep === 0) {
      setLockChangeProject(false);
    }
  }, [activeStep]);

  const fetchProjects = async () => {
    const response = await projectsAPIs.getProjects(
      qs.stringify({
        plan_since: moment().startOf('d').unix(),
        plan_till: moment().startOf('d').unix() * 100,
        isSelfRegister: true,
      })
    );
    return response.message;
  };

  const { isLoading: isLoadingProjects, data: projects = [] } = useQuery(['fetchProjects'], fetchProjects);

  const { shouldAlert, setShouldAlert } = useInactivityUser({
    shouldCheck: activeStep > 0 && user && !!selectedProject && activeStep < steps.length - 1,
  });

  // Handle Stepper
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrev = () => {
    if (activeStep === steps.length - 1) return;
    if (activeStep - 1 === 0) {
      handleAutoLogout();
    }
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleError = () => {
    handleAutoLogout();
    setHasError(true);
  };

  const selfRegisterRoles = useMemo(() => {
    const selectedProjectObj = projects.find((el) => el.uuid === selectedProject);
    if (!selectedProjectObj) return [];
    return selectedProjectObj.selfRegisterRoleUuids.map((selfRoleId) =>
      selectedProjectObj.clientGroups.find((role) => role.id === selfRoleId)
    );
  }, [projects, selectedProject]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return isLoadingProjects ? (
          <Loader />
        ) : (
          <StepOne
            projects={projects}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            activeStep={activeStep}
            steps={steps}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <StepOptions
            selectedProject={selectedProject}
            ticketNumber={ticketNumber}
            setTicketNumber={setTicketNumber}
            accountOption={accountOption}
            setAccountOption={setAccountOption}
            activeStep={activeStep}
            steps={steps}
            checkAbleNext={checkAbleNext}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <StepTwo
            roles={selfRegisterRoles}
            selectedRole={selfRegisterRoles.length === 1 && selfRegisterRoles[0]}
            activeStep={activeStep}
            steps={steps}
            selectedProject={selectedProject}
            lockChangeProject={lockChangeProject}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handleError={handleError}
          />
        );
      case 3:
        return (
          <StepThree
            activeStep={activeStep}
            steps={steps}
            selectedProject={selectedProject}
            checkAbleNext={checkAbleNext}
            refetchAnketaUserData={refetchAnketaUserData}
            handlePrev={selfRegisterRoles.length === 1 ? null : handlePrev}
            handleNext={handleNext}
            handleError={handleError}
          />
        );
      case 4:
        return (
          <StepFour
            activeStep={activeStep}
            steps={steps}
            selectedProject={selectedProject}
            setActiveStep={setActiveStep}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handleError={handleError}
          />
        );
      default:
        return null;
    }
  };

  const checkAbleNext = (step) => {
    switch (step) {
      case 0:
        return !!selectedProject;
      case 1:
        return !!accountOption && accountOption !== 'old';
      case 2:
        return !!user;
      case 3:
        return userData?.length > 0;
      default:
        return true;
    }
  };

  const renderContent = () => {
    return getStepContent(activeStep);
  };

  const handleAutoLogout = () => {
    logout();
    setActiveStep(0);
  };

  const handleClickLogo = () => {
    navigate(routes.homepage.path);
  };

  const selectedProjectData = projects?.find((p) => p.uuid === selectedProject);
  return (
    <>
      {shouldAlert ? (
        <AlertPopup setShouldAlert={setShouldAlert} callback={handleAutoLogout} />
      ) : hasError ? (
        <ErrorPopup setHasError={setHasError} />
      ) : null}
      <Container sx={{ padding: { xs: '0', md: '0 8px', minHeight: '100vh' } }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          gap="24px"
          padding="8px"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box style={{ cursor: 'pointer' }}>
            <img
              src="/images/logo-dark.png"
              alt="logo"
              width="auto"
              onClick={
                //handleClickLogo
                () => {} //TODO hardcode
              }
            />
          </Box>
          <Stack direction="row" gap="8px" alignItems="center" justifyContent="center" flexWrap="wrap">
            <LanguageMuiSelect />
            {user && (
              <>
                <ProfileSummary showStatus={false} />
                <Button variant="outlined" size="large" sx={{ borderRadius: '4px' }} onClick={handleAutoLogout}>
                  {localizeText.LOGOUT_BUTTON}
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        <main>
          <h1 style={{ textAlign: 'center', paddingBottom: '20px', fontSize: '40px' }}>{localizeText.SELF_REGISTER}</h1>
          {selectedProjectData && (
            <h2 style={{ textAlign: 'center' }}>
              {getLocalizationValue(selectedProjectData, 'fullName') || selectedProjectData.name}
            </h2>
          )}
          <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <StepperHeaderContainer>
              <StepperWrapper sx={{ height: '100%', '& .MuiStepLabel-label': { cursor: 'pointer' } }}>
                <Stepper connector={<></>} activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => {
                    return (
                      <Step
                        disabled={index === 2 && selfRegisterRoles.length === 1}
                        key={index}
                        onClick={() => {
                          if (index === 0 && lockChangeProject) return;
                          if (
                            Array(index)
                              .fill(0)
                              .every((_, s) => checkAbleNext(s))
                          ) {
                            setActiveStep(index);
                          }
                        }}
                        sx={{ '&.Mui-completed + svg': { color: 'primary.main' } }}
                      >
                        <StepLabel StepIconComponent={StepperCustomDot}>
                          <div className="step-label">
                            <Typography className="step-number">{`0${index + 1}`}</Typography>
                            <div>
                              <Typography className="step-title">{step.title}</Typography>
                              {/* <Typography className="step-subtitle">{step.sub title}</Typography> */}
                            </div>
                          </div>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </StepperWrapper>
            </StepperHeaderContainer>
            <div style={{ width: '100%' }}>
              <CardContent sx={{ height: '100%' }}>
                <Stack sx={{ height: '100%' }} justifyContent="space-between">
                  {renderContent()}
                </Stack>
              </CardContent>
            </div>
          </Card>
        </main>
      </Container>

      <ConfirmModal />
      <ImageCropperModal />
    </>
  );
}

export default SelfRegistrationPage;
