import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import TextFieldPhone from 'src/@core/components/mui/textfield/TextFieldPhone';
import Loader from 'src/components/loader';
import { SelectHookForm } from 'src/components/mui/select';
import { useLanguage } from 'src/hooks';
import { generalAPIs, userAPIs } from 'src/services';
import { getCountriesList } from 'src/store/actions/app';
import { registerAccount, updateUser } from 'src/store/actions/auth';
import { generateRandomPassword, getFullName } from 'src/utils/common';
import { LOCALIZATION, TEXT } from 'src/utils/constant';
import { registrationValidationSchema, selfRegistrationValidationSchema } from 'src/utils/formValidator';
import StepFooter from './StepFooter';

function StepTwo({
  activeStep,
  steps,
  selectedProject,
  lockChangeProject,
  roles,
  selectedRole,
  handleNext = () => {},
  handlePrev = () => {},
  handleError = () => {},
}) {
  const user = useSelector((state) => state.auth.user);

  const { lang, localizeText, localizeMessage } = useLanguage();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { roleUuid: '' },
    resolver: yupResolver(selfRegistrationValidationSchema(lang)),
  });

  const onSubmit = async (values) => {
    const { roleUuid } = values;
    const fullname = 'Саморегистрирующийся посетитель';
    const password = generateRandomPassword();

    if (user) {
      return handleNext();
    }

    setIsSubmitting(true);
    const isSuccess = await registerAccount(
      {
        fullname,
        password,
        email: '',
        projectUuid: selectedProject,
        roleUuid: roleUuid,
      },
      false
    );
    setIsSubmitting(false);
    if (isSuccess) {
      handleNext();
    } else {
      handleError();
    }
  };

  useEffect(() => {
    if (user) {
      reset({ roleUuid: user.projectSettings?.[selectedProject]?.mainRoleId });
    }
  }, [user, selectedProject]);

  useEffect(() => {
    if (selectedRole?.id) onSubmit({ roleUuid: selectedRole.id });
  }, [selectedRole]);

  if (selectedRole?.id) return <Loader />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={6} justifyContent="center" marginY="20px" sx={{ '& *': { fontSize: '18px' } }}>
          <Grid item xs={12} md={8}>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
              {localizeMessage.PLEASE_SELECT_A_ROLE}:
            </Typography>
            <Controller
              name="roleUuid"
              control={control}
              render={({ field }) => (
                <SelectHookForm
                  required={true}
                  label={localizeText.ROLE}
                  selectProps={{ value: selectedRole?.id || field.value, onChange: field.onChange }}
                  error={errors.roleUuid}
                  errorComponent={
                    <>{errors.roleUuid && <FormHelperText error={true}>{errors.roleUuid.message}</FormHelperText>}</>
                  }
                  disabled={!!user || !!selectedRole?.id}
                >
                  {roles.map((option, i) => (
                    <MenuItem key={`roleUuid-${i}`} value={option.id}>
                      <>{option?.name?.[lang] || option?.name?.[LOCALIZATION.ru_RU]}</>
                    </MenuItem>
                  ))}
                </SelectHookForm>
              )}
            />
          </Grid>
        </Grid>
      </form>
      <StepFooter
        activeStep={activeStep}
        steps={steps}
        isSubmitting={isSubmitting}
        lockChangeProject={lockChangeProject}
        handlePrev={handlePrev}
        handleNext={() => {
          handleSubmit(onSubmit)();
        }}
      />
    </>
  );
}

export default StepTwo;
