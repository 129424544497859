import { CheckCircle, Help } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { AvatarUser } from 'src/components/avatar-user';
import UserRole from 'src/components/user-role';
import UserStatus from 'src/components/user-status';
import { useLanguage } from 'src/hooks';
import { getFullName } from 'src/utils/common';

function UserProfile({ user, projectUuid, role, isLocked, showAddNew, onEditProfile }) {
  const isAccredited = user?.projectSettings?.[projectUuid]?.isAccredited;
  const readyToAccreditate = user?.projectSettings?.[projectUuid]?.readyToAccreditate;
  const { lang, localizeText } = useLanguage();
  const avatarURL = useMemo(() => {
    try {
      return URL.createObjectURL(user.avatarFile);
    } catch (error) {
      return '';
    }
  }, [user]);

  return (
    <Grid container spacing={8} alignItems="center">
      <Grid item xs={12} md={isLocked ? 9 : 12}>
        <Stack direction="row" gap="20px">
          <Grid container>
            <Grid container xs={12} sm={6}>
              <Avatar
                src={avatarURL}
                sx={{
                  background: '#9D9D9D !important',
                  borderRadius: 0,
                  zIndex: 9,
                  border: '4px solid #FFF',
                  width: 127,
                  height: 127,
                  marginTop: '-50px',
                }}
              >
                {(user?.lastName?.[0] || '') + (user?.firstName?.[0] || '')}
              </Avatar>
              <Grid marginLeft="10px">
                <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#3A3541DE' }}>
                  {getFullName(user)}
                </Typography>
                <Typography sx={{ fontSize: '12px', color: '#3A354199' }}>{user?.email}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} sm={6}>
              <Stack gap="10px" sx={{ flex: '1 1 auto', '& *': { whiteSpace: 'nowrap' } }}>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Tooltip
                    title={
                      projectUuid === '5c7424bb-d5a0-17e7-7f0a-a595cd65b3c7' ? (
                        <div
                          style={{ fontSize: '14px' }}
                          dangerouslySetInnerHTML={{ __html: localizeText.ANKETA_STATUS_HINT }}
                        />
                      ) : (
                        ''
                      )
                    } //TODO hardcode
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', color: '#89868D' }}>
                      {localizeText.ANKETA_STATUS_TEXT}:
                      <Help sx={{ color: '#3A35418A', fontSize: '14px' }} />
                    </Typography>
                  </Tooltip>
                  <UserStatus
                    isAccredited={isAccredited}
                    readyToAccreditate={readyToAccreditate}
                    sx={{ height: '30px', alignItems: 'center', display: 'flex' }}
                    icon={<CheckCircle />}
                    project={projectUuid}
                  />
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Tooltip
                    title={
                      projectUuid === '5c7424bb-d5a0-17e7-7f0a-a595cd65b3c7' ? (
                        <div
                          style={{ fontSize: '14px' }}
                          dangerouslySetInnerHTML={{ __html: localizeText.USER_ROLE_HINT }}
                        />
                      ) : (
                        ''
                      )
                    } //TODO hardcode
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', color: '#89868D' }}>
                      {localizeText.USER_ROLE_TEXT}:
                      <Help sx={{ color: '#3A35418A', fontSize: '14px' }} />
                    </Typography>
                  </Tooltip>
                  <UserRole role={role} sx={{ alignItems: 'center', display: 'flex' }} icon={<CheckCircle />} />
                </Stack>
              </Stack>
            </Grid>
            <Grid container xs={12} marginTop="20px">
              <Tooltip
                title={
                  projectUuid === '5c7424bb-d5a0-17e7-7f0a-a595cd65b3c7' ? (
                    <div style={{ fontSize: '14px' }}>{localizeText.ANKETA_QUESTION_HINT}</div>
                  ) : (
                    ''
                  )
                } //TODO hardcode
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px',
                  backgroundColor: '#F5F5F5',
                  padding: '10px',
                }}
              >
                <Help sx={{ color: '#3A35418A', fontSize: '14px' }} />
                <Typography sx={{ fontSize: '12px', lineHeight: '18px', color: '#89868D', cursor: 'default' }}>
                  {/* <Typography component="span" fontSize="inherit" color="inherit" sx={{ textDecoration: 'underline' }}>
                    Как добавить нового участника?
                  </Typography>
                  <br /> */}
                  {localizeText.ANKETA_QUESTION_TEXT}
                  {/* <Typography
                    component="span"
                    fontSize="inherit"
                    sx={{ textDecoration: 'underline', color: '#524E58' }}
                  >
                    Подробнее
                  </Typography> */}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      {/* {!showAddNew && isLocked && (
        <Grid item xs={12} md={3}>
          <Button
            variant="outlined"
            sx={{
              textTransform: 'uppercase',
              fontWeight: 700,
              border: '3px solid #737791',
              padding: '20px 40px',
              '&:hover': { border: '3px solid #737791A1' },
            }}
            onClick={onEditProfile}
          >
            редактировать анкету
          </Button>
        </Grid>
      )} */}
    </Grid>
  );
}

export default UserProfile;
